import axios from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // ** For Refreshing Token
  subscribers = []

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    axios.defaults.paramsSerializer = (params) => {
      // Sample implementation of query string building
      let result = ''
      Object.keys(params).forEach(key => {
        result += `${key}=${encodeURI(params[key])}&`
      })
      return result.substring(0, result.length - 1)
    }

    // ** Request Interceptor
    axios.interceptors.request.use(
        config => {
          // ** Get token from localStorage
          const accessToken = this.getToken()

          if (this.getExpiresAt() && this.getExpiresAt() - Math.floor((Date.now() / 1000)) < 0) {
            this.handleNoToken()
            return config
          }

          // ** If token is present add it to request's Authorization Header
          if (accessToken) {
            // ** eslint-disable-next-line no-param-reassign
            config.headers.Authorization = `${this.jwtConfig.tokenType} ${JSON.parse(accessToken)}`
          }
          return config
        },
        error => Promise.reject(error)
    )

    // ** Add request/response interceptor
    axios.interceptors.response.use(
        response => response,
        error => {
          // ** const { config, response: { status } } = error
          const { response } = error
          // const originalRequest = config

          // ** if (status === 401) {
          if (response && (response.status === 401 || response.status === 403)) {
            const pathname = window.location.pathname
            // console.log(pathname)
            if (pathname !== "/login") {
              this.handleNoToken()
            }
          }
          return Promise.reject(error)
        }
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getExpiresAt() {
    return localStorage.getItem(this.jwtConfig.storageExpiredToken)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setExpiresAt(value) {
    localStorage.setItem(this.jwtConfig.storageExpiredToken, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  setUserData(value) {
    localStorage.setItem('userData', value)
  }

  deleteToken() {
    localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
  }

  deleteRefreshToken() {
    localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  deleteExpiresAt() {
    localStorage.removeItem(this.jwtConfig.storageExpiredToken)
  }

  deleteUserData() {
    localStorage.removeItem('userData')
  }

  login(...args) {
    return axios.post(this.jwtConfig.loginEndpoint, ...args)
  }

  getDetailProfile() {
    return axios.get(this.jwtConfig.getDetailEndpoint)
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return axios.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken()
    })
  }

  handleNoToken() {
    this.deleteToken()
    this.deleteRefreshToken()
    this.deleteUserData()
    this.deleteExpiresAt()
    window.location.reload()
  }
}
