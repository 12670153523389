// ** Redux Imports
import { createSlice, createAsyncThunk} from '@reduxjs/toolkit'

export const getService = createAsyncThunk('appService/getService', async service => {
    return service
})

export const appServiceSlice = createSlice({
    name: 'appService',
    initialState: {
        data: [],
        total: 1,
        params: {},
        allData: [],
        selectedService: null
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getService.fulfilled, (state, action) => {
                state.selectedService = action.payload
            })
    }
})

export default appServiceSlice.reducer
