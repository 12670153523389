// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import url from "../../../configs/pathConfig"

export const getAllData = createAsyncThunk('appAccount/getAllData', async () => {
  // const response = await axios.get('/api/users/list/all-data')
  const response = await axios.get(`${url.portal}/user`)
  return response.data
})

export const getData = createAsyncThunk('appAccount/getData', async params => {
  const response = await axios.get(`${url.portal}/user`, {params})
  return {
    params,
    data: response.data.rows,
    totalPages: response.data.totalPage
  }
})

export const getAccount = createAsyncThunk('appAccount/getAccount', async () => {
  // const response = await axios.get('/api/users/profile', { id })
  const response = await axios.get(`${url.portal}/user`)
  return response.data
})

export const addAccount = createAsyncThunk('appAccount/addAccount', async (account, { dispatch, getState }) => {
  // await axios.post('/user/users/add-profile', profile)
  await axios.post(`${url.portal}/user`, account)
  await dispatch(getData(getState().account.params))
  await dispatch(getAllData())
  return account
})

export const editAccount = createAsyncThunk('appAccount/editAccount', async (account, { dispatch, getState }) => {
  // await axios.post('/user/users/add-profile', profile)
  const response = await axios.put(`${url.portal}/user`, account)
  await dispatch(getData(getState().account.params))
  return {status : response.status, data :response }
})

export const deleteAccount = createAsyncThunk('appAccount/deleteAccount', async (id, { dispatch, getState }) => {
  await axios.delete(`${url.portal}/user`)
  await dispatch(getData(getState().account.params))
  await dispatch(getAllData())
  return id
})

export const appAccountSlice = createSlice({
  name: 'appAccount',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedAccount: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.totalPages = action.payload.totalPages
      })
      .addCase(getAccount.fulfilled, (state, action) => {
        state.selectedAccount = action.payload
      })
  }
})

export default appAccountSlice.reducer
