// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import service from '../views/apidoc/store'
import account from '@src/views/account/store'

const rootReducer = {
  auth,
  navbar,
  service,
  account,
  layout
}

export default rootReducer
