// eslint-disable-next-line no-unused-vars
const urlProduction = {
    public : "https://portalapi.synxchro.co.id/api/v1/portal/public",
    service : "https://portalapi.synxchro.co.id/api/v1/portal/public/service",
    sandbox : "https://sandbox.synxchro.co.id",
    portal : "https://portalapi.synxchro.co.id/api/v1/portal"
}

// eslint-disable-next-line no-unused-vars
const urlDevelopmentLocal = {
    public : "https://portalapi.synxchro.co.id/api/v1/portal/public",
    service : "https://portalapi.synxchro.co.id/api/v1/portal/public/service",
    sandbox : "https://sandbox.synxchro.co.id",
    portal : "https://portalapi.synxchro.co.id/api/v1/portal"
}

// eslint-disable-next-line no-unused-vars
const urlDevelopmentCloud = {
    public : "https://portalapi.synxchro.co.id/api/v1/portal/public",
    service : "https://portalapi.synxchro.co.id/api/v1/portal/public/service",
    sandbox : "https://sandbox.synxchro.co.id",
    portal : "https://portalapi.synxchro.co.id/api/v1/portal"
}

// eslint-disable-next-line no-unused-vars
const urlLocal = {
    public : "https://portalapi.synxchro.co.id/api/v1/portal/public",
    service : "https://portalapi.synxchro.co.id/api/v1/portal/public/service",
    sandbox : "https://sandbox.synxchro.co.id",
    portal : "https://portalapi.synxchro.co.id/api/v1/portal"
}

export default urlDevelopmentCloud