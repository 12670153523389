// ** Logo
import logoLight from '@src/assets/images/logo/logo-synxchro-compact-red.png'
import logoDark from '@src/assets/images/logo/logo-synxchro-compact-white.png'

import { useSelector } from 'react-redux'

const SpinnerComponent = () => {
  const store = useSelector(state => state.layout)
  return (
    <div className='fallback-spinner app-loader'>
        <img src={store.skin === 'light' ? logoLight : logoDark} alt='logo' style={{height: '78px', width:"78px"}}/>
      <div className='loading'>
        <div className='effect-1 effects'></div>
        <div className='effect-2 effects'></div>
        <div className='effect-3 effects'></div>
      </div>
    </div>
  )
}

export default SpinnerComponent
