import CryptoJs from 'crypto-js'
import url from "../configs/pathConfig"

// ** Custom Hooks
import useJwt from '@src/auth/jwt/useJwt'
const config = useJwt.jwtConfig

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
      /* eslint-disable operator-linebreak */
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
      /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const formatDateTime = (value, formatting = { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'}) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-GB', formatting).format(new Date(value)).replace(",", "")
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole.toLocaleUpperCase() === 'ADMIN') return '/'
  if (userRole.toLocaleUpperCase() === 'CLIENT') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const parseAbilityList = abilityList => {
  const ability = []
  for (let i = 0; i < abilityList.length; i++) {
    const a = abilityList[i]
    ability.push({action: a})
  }
  return ability
}

export const encrypt = (data) => {
  return CryptoJs.AES.encrypt(JSON.stringify(data), config.secretKey).toString()
}

export const decrypt = (data) => {
  return CryptoJs.AES.decrypt(data, config.secretKey).toString(CryptoJs.enc.Utf8)
}

export const setOptionsByType = (type, data) => {
  const option = []
  if (type === 'node' || type === 'privilege') {
    for (let i = 0; i < data.length; i++) {
      option.push({value: data[i].id, label: data[i].name})
    }
  }
  if (type === 'driver') {
    for (let i = 0; i < data.length; i++) {
      option.push({value: data[i].driverCode, label: data[i].driverName})
    }
  }
  if (type === 'schema') {
    for (let i = 0; i < data.length; i++) {
      option.push({value: data[i].id, label: data[i].schemaName})
    }
  }
  if (type === 'connection') {
    for (let i = 0; i < data.length; i++) {
      option.push({value: data[i].id, label: data[i].connName})
    }
  }
  if (type === 'scheduler') {
    for (let i = 0; i < data.length; i++) {
      option.push({value: data[i].id, label: data[i].schedulerName})
    }
  }
  if (type === 'userGroup') {
    for (let i = 0; i < data.length; i++) {
      option.push({value: data[i].id, label: data[i].name})
    }
  }
  if (type === 'l1Deployment') {
    for (let i = 0; i < data.length; i++) {
      option.push({value: data[i].id, label: data[i].description})
    }
  }
  if (type === 'type') {
    const value = Object.keys(data)
    const label = Object.values(data)
    for (let i = 0; i < value.length; i++) {
      option.push({value: value[i], label: label[i]})
    }
  }
  if (type === 'nav') {
    for (let i = 0; i < data.length; i++) {
      const children = []
      for (let j = 0; j < data[i].endPoints.length; j++) {
        children.push({value: data[i].endPoints[j].name, title: data[i].endPoints[j].name, id: data[i].endPoints[j].id, navLink: `/documentation/${data[i].categoryCode}/${data[i].endPoints[j].id}`, category: data[i].categoryName, path: `${url.sandbox}/${data[i].endPoints[j].path}`})
      }
      option.push({value: data[i].categoryCode, title: data[i].categoryName, id: data[i].id, navLink: `/documentation/${data[i].categoryCode}`, children})
    }
  }
  if (type === 'header') {
    for (let i = 0; i < data.length; i++) {
      option.push({value: data[i].headerVal, key: data[i].headerKey})
    }
  }
  if (type === 'param') {
    for (let i = 0; i < data.length; i++) {
      option.push({value: null, key: data[i].paramField})
    }
  }
  if (type === 'body') {
    for (let i = 0; i < data.length; i++) {
      option.push({value: null, key: data[i].bodyField})
    }
  }
  return option
}

export const getListKeyFromJson = (data) => {
  const array = []
  for (const i in data) {
    array.push(i)
  }
  return array
}

export const renderIsActive = (data) => {
  return data === 1 ? 'Active' : 'Inactive'
}

export const renderFilter = (filter) => {
  const objFilter = Object.entries(filter)
  let stringFilter = '( '
  let isFirst = true
  for (let i = 0; i < objFilter.length; i++) {
    if (objFilter[i][1] !== null) {
      if (isFirst) {
        if (objFilter[i][0] === 'jobType') {
          stringFilter = `${stringFilter}${objFilter[i][0]}:*${objFilter[i][1].value}*`
        } else {
          stringFilter = `${stringFilter}${objFilter[i][0]}:*${objFilter[i][1]}*`
        }
        isFirst = false
      } else {
        if (objFilter[i][0] === 'jobType') {
          stringFilter = `${stringFilter} OR ${objFilter[i][0]}:*${objFilter[i][1].value}*`
        } else {
          stringFilter = `${stringFilter} OR ${objFilter[i][0]}:*${objFilter[i][1]}*`
        }
      }
    }
  }
  if (stringFilter.trim() === '(') {
    return null
  }
  return `${stringFilter} )`
}

export const renderFilterWithoutParenthesis = (filter) => {
  const objFilter = Object.entries(filter)
  let stringFilter = ''
  let isFirst = true
  for (let i = 0; i < objFilter.length; i++) {
    if (objFilter[i][1] !== null && objFilter[i][1] !== '' && objFilter[i][1] !== null) {
      if (isFirst) {
        if (objFilter[i][0] === 'jobType') {
          stringFilter = `${stringFilter}${objFilter[i][0]}:*${objFilter[i][1].value}*`
        } else {
          stringFilter = `${stringFilter}${objFilter[i][0]}:*${objFilter[i][1]}*`
        }
        isFirst = false
      } else {
        if (objFilter[i][0] === 'jobType') {
          stringFilter = `${stringFilter} OR ${objFilter[i][0]}:*${objFilter[i][1].value}*`
        } else {
          stringFilter = `${stringFilter} OR ${objFilter[i][0]}:*${objFilter[i][1]}*`
        }
      }
    }
  }
  if (stringFilter.trim() === '') {
    return null
  }
  return `${stringFilter}`
}

export const toUpperFolder = (fullPath) => {
  const path = fullPath.split("/")
  let targetPath = ''
  for (let i = 0; i < path.length - 2; i++) {
    targetPath = `${targetPath + path[i]}/`
  }
  return targetPath
}

export const pathSlashChecker = (path) => {
  return path.slice(-1).includes("/") ? path : `${path}/`
}

export const planChecker = (subscriptionCode) => {
  return parseInt(subscriptionCode.substring(subscriptionCode.length, subscriptionCode.length - 1))
}

export const numberWithDot = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}